@charset 'UTF-8';
@import "/Users/alivevn/Website/vhost/nankai-densetsu/src/scss/utility/_media-queries.scss";
@import "/Users/alivevn/Website/vhost/nankai-densetsu/src/scss/utility/_mixin.scss";


.fv{
  background: #0b4c94;
  position: relative;
  .container1400{
    position: relative;
    min-height: 686px;
    background-size: auto 100%;
    background-position: center left;
    padding-top: 12px;
    @include SP{
      min-height: 0;
      background-size: contain;
      background-position: center;
      height: 79vw;
    }
  }
  @include SP{
    .fv-icon{
      position: relative;
    }
  }

  .fv-icon{
    width: 770px;
    @include SP{
      width: calc(100% - 60px);
      margin: auto;
    }
    .swiper-slide{
      opacity: 0 !important;
      @include trans;
      &.swiper-slide-active{
        opacity: 1 !important;
      }
    }
  }
  .fv-ctn{
    top: 48%;
    left: 52%;
    padding-right: 20px;
    position: absolute;
    color: #fff;
    transform: translateY(-50%);
    @include max-screen(1300px){
      right: 0;
    }
    @include SP{
      left: 0;
      right: 0;
      top: 50%;
      padding: 0 20px;
      text-align: center;
    }
    &__hd{
      font-family: $ffNotoSerif;
      @include font(67, 1.4, '', 500);
      margin-bottom: 30px;
      @include max-screen(1300px){
        @include font(50);
      }
      @include SP{
        @include font(25);
        margin-bottom: 10px;
        @include IP5{
          @include font(20)
        }
      }
    }
    &__des{
      @include font(25, 51px, '', bold);
      @include max-screen(1150px){
        @include font(20, 2);
      }
      @include SP{
        @include font(16, 1.8);
        margin-bottom: 20px;
        @include IP5{
          @include font(14);
        }
      }
    }
    &__btn{
      position: absolute;
      background: #fff;
      color: #005BB2;
      display: block;
      right: 50px;
      bottom: 8px;
      width: 200px;
      height: 40px;
      @include font(19, 24px, '', bold);
      padding: 8px 20px;
      border-radius: 25px;
      @include SP{
        position: relative;
        margin: auto;
        right: 0;
        bottom: 0;
        @include font(14);
      }
      &:before{
        content: "";
        width: 11px;
        height: 17px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 20px;
        margin: auto;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg id='メインビジュアル' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 17'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%2307569e;%7D%3C/style%3E%3C/defs%3E%3Cpolygon class='cls-1' points='6.3 0 0 0 4.5 8.5 0 17 6.3 17 11 8.5 6.3 0'/%3E%3C/svg%3E");
      }
      &:after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        z-index: -2;
        @include trans;
      }
      @include HOVER{
        &:hover{
          opacity: 1;
          &:after {
            box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
          }
        }
      }
    }
  }
}

.top-box01{
  background: #0b4c94;
  padding-top: 111px;
  padding-bottom: 127px;
  @include SP{
    padding-top: 100px;
    padding-bottom: 0;
  }
  .top-box-ctn{
    @include clearfix;
    margin-bottom: 100px;
    @include SP{
      width: calc(100% + 40px);
      margin: 0 -20px 50px;
    }
    &:last-child{
      margin-bottom: 0;
    }
    &__img{
      width: 715px;
      float: left;
      @include max-screen(1150px){
        width: 600px;
      }
      @include SP{
        float: none;
        width: 100%;
        position: relative;
        z-index: 1;
      }
    }
    .top-box-ctn-box{
      width: calc(100% - 715px);
      float: left;
      @include flexbox;
      @include align-items;
      @include max-screen(1150px){
        width: calc(100% - 600px);
      }
      @include SP{
        width: 100%;
        margin-top: -70px;
      }
      .box-cnt{
        background: #195a9d;
        position: relative;
        padding: 75px;
        padding-right: 50px;
        padding-bottom: 55px;
        width: 100%;
        color: #fff;
        @include SP{
          padding-top: 113px;
          padding-right: 20px;
          padding-bottom: 30px;
        }
        &:before{
          content: "";
          width: 42px;
          height: 72px;
          position: absolute;
          top: 30px;
          left: 30px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg id='コンテンツ' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 72'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpolygon class='cls-1' points='24.06 0 0 0 17.94 36 0 72 24.06 72 42 36 24.06 0'/%3E%3C/svg%3E");
          @include SP{
            top: 70px;
            left: 20px;
          }
        }
        &__num{
          font-family: $ffRoboto;
          @include font(35, 1, '', bold);
          margin-bottom: 5px;
        }
        &__title{
          @include font(33, 51px, '', bold);
          margin-bottom: 15px;
          @include max-screen(1150px){
            @include font(25);
          }
          @include SP{
            @include font(20, 1.8);
            margin-bottom: 10px;
          }
        }
        &__des{
          @include font(16, 24px, -.03em);
          margin-bottom: 20px;
          @include SP{
            @include font(14, 20px)
          }
        }
        &__btn{
          background: #fff;
          display: block;
          text-align: center;
          @include font(14, 16px, '', bold);
          color: #005BB2;
          padding: 9px;
          border-radius: 30px;
          width: 163px;
          position: relative;
          &:before{
            content: "";
            width: 12px;
            height: 18px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 20px;
            margin: auto;
            background-size: 100% 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg id='コンテンツ' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11.18 17.43'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%2307569e;%7D%3C/style%3E%3C/defs%3E%3Cpolygon class='cls-1' points='6.4 0 0 0 4.78 8.72 0 17.43 6.4 17.43 11.18 8.72 6.4 0'/%3E%3C/svg%3E");
          }
          &:after {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            position: absolute;
            @include trans;
          }
          @include HOVER{
            &:hover{
              opacity: 1;
              &:after {
                box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
              }
            }
          }
        }
      }
    }
    @include PC{
      &--right{
        .top-box-ctn__img, .top-box-ctn-box{
          float: right;
        }
      }
    }
  }
}

.top-new{
  padding-top: 44px;
  padding-bottom: 100px;
  @include SP{
    padding-bottom: 44px;
  }
  .box-title{
    margin-bottom: 15px;
  }
  .new-list{
    padding: 0 50px;
    @include SP{
      padding: 0;
    }
    .item{
      border-bottom: 1px solid rgba(0, 65, 123, .1);
      padding: 20px 0;
      position: relative;
      @include flexbox;
      @include flex-wrap;
      @include align-items;
      &:before{
        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        border-top: 1px solid #000;
        border-right: 1px solid #000;
        transform: rotate(45deg);
      }
      &__date{
        color: #0044A2;
        @include font(16, 19px, '', bold);
        margin-right: 30px;
        @include SP{
          margin-right: 20px;
          margin-bottom: 10px;
        }
      }
      &__cat{
        border: 1px solid #000;
        text-align: center;
        @include font(12, 15px, '', bold);
        padding: 5px 20px;
        border-radius: 20px;
        margin-right: 35px;
        @include SP{
          margin-bottom: 10px;
          margin-right: 0;
        }
      }
      &__title{
        @include font(16, 24px);
        @include multiLineEllipsis;
        @include SP{
          margin-top: 10px;
          padding-right: 20px;
          @include multiLineEllipsis(2);
        }
      }
    }
  }
  &__btn{
    color: #fff !important;
    background: #07569E;
    text-align: center;
    @include font(14, 16px, '', bold);
    padding: 9px;
    padding-right: 20px;
    width: 123px;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: 50px;
    border-radius: 20px;
    margin-top: 30px;
    @include SP{
      width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
    &:before{
      content: "";
      width: 12px;
      height: 18px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 20px;
      margin: auto;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      background-image: url("data:image/svg+xml, %3Csvg id='コンテンツ' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11.18 17.43'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpolygon class='cls-1' points='6.4 0 0 0 4.78 8.72 0 17.43 6.4 17.43 11.18 8.72 6.4 0'/%3E%3C/svg%3E");
    }
    @include HOVER{
      &:hover{
        opacity: 0.7 !important;
      }
    }
  }
}

.top-our-company{
  padding-top: 50px;
  padding-bottom: 95px;
  background: #EEE;
  @include SP{
    padding-bottom: 50px;
  }
  .box-title{
    margin-bottom: 36px;
  }
  .top-our-company-list01{
    @include flexbox;
    @include flex-wrap;
    padding: 0 50px;
    margin-bottom: 40px;
    @include SP{
      padding: 0;
    }
    .item{
      width: calc(50% - 25px);
      margin-right: 50px;
      border: 1px solid #07569E;
      background: #fff;
      padding: 25px 30px;
      min-height: 128px;
      position: relative;
      @include SP{
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        padding: 15px 20px;
        min-height: 100px;
      }
      &:before{
        content: "";
        width: 29px;
        height: 36px;
        background: #EEE;
        border-left: 1px solid #07569E;
        position: absolute;
        top: -15px;
        right: -12px;
        transform: rotate(-45deg);
      }
      &__jp{
        @include font(30, 1, '', bold);
        display: inline-block;
        vertical-align: middle;
        color: #07569E;
        @include SP{
          @include font(20)
        }
      }
      &__en{
        display: inline-block;
        vertical-align: middle;
        font-family: $ffRoboto;
        @include font(20, 1);
        color: #07569E;
        margin-top: 3px;
        margin-left: 15px;
        opacity: 0.5;
        @include SP{
          display: block;
          margin-left: 0;
          margin-top: 10px;
          @include font(15, 1);
        }
      }
      &__fake{
        position: absolute;
        right: 9px;
        bottom: 11px;
        color: #07569E;
        @include font(16, 24px, -.06em, bold);
        @include SP{
          @include font(12, 16px);
        }
        &:after{
          content: "";
          width: 24px;
          height: 24px;
          display: inline-block;
          vertical-align: middle;
          margin-left: 15px;
          transform: translateY(-1px);
          background-size: 100% 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg id='コンテンツ' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.7 23.7'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%2307569e;%7D.cls-2%7Bfill:none;stroke:%23fff;stroke-miterlimit:10;stroke-width:2px;%7D%3C/style%3E%3C/defs%3E%3Ccircle class='cls-1' cx='11.85' cy='11.85' r='11.85'/%3E%3Cpolyline class='cls-2' points='9.35 6.85 14.35 11.85 9.35 16.85'/%3E%3C/svg%3E");
          @include SP{
            width: 16px;
            height: 16px;
            margin-left: 5px;
          }
        }
      }
      &:nth-child(2n){
        margin-right: 0;
      }
    }
  }
  .top-our-company-box{
    padding: 0 50px;
    @include flexbox;
    @include flex-wrap;
    @include SP{
      padding: 0;
    }
    &.mt30{
      margin-top: 30px;
    }
    .top-our-company-box-title{
      width: 200px;
      @include SP{
        width: 100%;
        margin-bottom: 15px;
      }
      &__jp{
        @include font(24, 29px);
        @include SP{
          margin-bottom: 5px;
        }
      }
      &__en{
        font-family: $ffRoboto;
        @include font(16, 19px);
        opacity: 0.5;
        margin-top: 8px;
      }
    }
    .top-our-company-box-list{
      width: calc(100% - 200px);
      @include flexbox;
      @include flex-wrap;
      @include SP{
        width: 100%;
      }
      li{
        width: calc(25% - 30px / 4);
        max-width: 192px;
        margin-right: 10px;
        margin-bottom: 10px;
        @include SP{
          width: calc(50% - 5px);
          max-width: 100%;
          margin-right: 10px;
          img{
            width: 100%;
          }
          &:nth-child(2n){
            margin-right: 0;
          }
        }
        &:nth-child(4n){
          margin-right: 0;
        }
      }
    }
  }
}